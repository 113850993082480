// Helper to get all dates between two given dates
import { NexoyaDailyPredictionTotal, NexoyaFunnelStepPredictionScore } from '../../../types';
import dayjs from 'dayjs';

export const getDateRange = (startDate: Date, endDate: Date): string[] => {
  const allDates: Array<string> = [];
  let currentDate = dayjs(startDate);
  const finalDate = dayjs(endDate);

  while (currentDate.isBefore(finalDate) || currentDate.isSame(finalDate)) {
    allDates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  return allDates;
};

// Helper to fill missing daily predictions in a funnel step
export const fillDailyPredictions = (funnelStep: NexoyaFunnelStepPredictionScore, dateRange: string[]) =>
  dateRange.map((date) => {
    const existingPrediction = funnelStep.dailyPredictions.find((p) => p.day === date);
    return (
      existingPrediction || {
        achieved: 0,
        day: date,
        predicted: 0,
        score: 0,
      }
    );
  });

// Helper to fill missing totals for dailyPredictionTotal
export const fillDailyPredictionTotal = (
  dailyPredictionTotal: NexoyaDailyPredictionTotal[],
  dateRange: Array<string>,
) =>
  dateRange.map((date) => {
    const existingPrediction = dailyPredictionTotal.find((p) => p.day === date);
    return (
      existingPrediction || {
        day: date,
        achieved: 0,
        predicted: 0,
        score: 0,
      }
    );
  });
