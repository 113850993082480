import React, { useState } from 'react';

import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { toast } from 'sonner';

import { NexoyaNewOptimizationSummary, NexoyaPortfolioType } from '../../../../types';

import { usePortfolio } from '../../../../context/PortfolioProvider';
import { useCreateOptimization } from '../../../../graphql/optimization/mutationCreateOptimization';
import { useActiveOptimization } from '../../../../graphql/optimization/queryActiveOptimization';
import { useNewOptimizationSummary } from '../../../../graphql/optimization/queryNewOptimizationSummary';

import { track } from '../../../../constants/datadog';
import { EVENT } from '../../../../constants/events';
import { format, GLOBAL_DATE_FORMAT } from '../../../../utils/dates';

import Button from '../../../../components/Button';
import ButtonAsync from '../../../../components/ButtonAsync';
import ButtonIcon from '../../../../components/ButtonIcon';
import Checkbox from '../../../../components/Checkbox';
import { SingleDateSelector } from '../../../../components/DateSelector';
import { useDialogState } from '../../../../components/Dialog';
import FormattedCurrency from '../../../../components/FormattedCurrency';
import Tooltip from '../../../../components/Tooltip';
import { CancelIcon } from '../../../../components/icons';
import Calendar from '../../../../components/icons/Calendar';
import SvgWarning from '../../../../components/icons/Warning';

import { BrickLoader } from '../../../Portfolio';
import { LaunchOptimizationSuccessDialog } from './LaunchOptimizationSuccessDialog';
import {
  DatepickerLabelStyled,
  DateSelectorWrapper,
  DialogActionsStyled,
  DialogContentStyled,
  DialogHeaderStyled,
  DialogStyled,
  DialogSubtitleStyled,
  DialogTitle,
  ErrorStatusWrapper,
  StatusContainer,
  StatusesContainer,
  StatusWrapperStyled,
} from './styles';

const getErrorMessages = (summary: NexoyaNewOptimizationSummary, isTargetPortfolio: boolean, skipErrors: any) => {
  const errorMessages = [];

  if (isTargetPortfolio && summary?.isOptiPeriodSpanningMultipleTargetItems) {
    errorMessages.push({
      id: 'isOptiPeriodSpanningMultipleTargetItems',
      message: 'The optimization overlaps multiple target items.',
      isSkippable: false,
      isSkipped: false,
    });
  }
  if (isTargetPortfolio && !summary?.targetItem?.targetItemId) {
    errorMessages.push({
      id: 'noTargetItem',
      message: 'Optimizing requires a planned target item. Please define at least one to proceed.',
      isSkippable: false,
      isSkipped: false,
    });
  }
  if (!isTargetPortfolio && !summary?.totalBudget) {
    errorMessages.push({
      id: 'noBudget',
      message: 'Optimizing requires a planned budget item. Please define at least one to proceed.',
      isSkippable: false,
      isSkipped: false,
    });
  }
  if (!summary?.isCustomImportDataFresh) {
    errorMessages.push({
      id: 'isCustomImportDataFresh',
      message: 'Your custom import data appears to be outdated. Please refresh it to proceed.',
      isSkippable: true,
      isSkipped: skipErrors.isCustomImportDataFresh,
    });
  }
  if (!summary?.allContentsHaveImpactGroupAssigned) {
    errorMessages.push({
      id: 'allContentsHaveImpactGroupAssigned',
      message: 'Please ensure each content item is assigned to an impact group.',
      isSkippable: false,
      isSkipped: false,
    });
  }
  return errorMessages;
};

export const LaunchOptimization = ({ portfolioId }) => {
  const [skipErrors, setSkipErrors] = useState({
    isCustomImportDataFresh: false,
  });
  const [optimizationEndDate, setOptimizationEndDate] = useState<Date | null>(dayjs().add(6, 'd').toDate());
  const {
    data: summaryData,
    loading: summaryLoading,
    error: summaryError,
  } = useNewOptimizationSummary({
    portfolioId,
    end: dayjs(optimizationEndDate).format(GLOBAL_DATE_FORMAT),
  });
  const { data: activeOptimizationData, loading } = useActiveOptimization({ portfolioId });
  const [createOptimization, { loading: loadingOptimization }] = useCreateOptimization({
    portfolioId,
    end: dayjs(optimizationEndDate).format(GLOBAL_DATE_FORMAT),
    skipCustomImportCheck: skipErrors.isCustomImportDataFresh,
  });
  const {
    portfolioV2Info: {
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();

  const { isOpen, toggleDialog } = useDialogState({
    initialState: false,
  });

  const {
    isOpen: isOpenSuccessDialog,
    toggleDialog: toggleSuccessDialog,
    closeDialog,
  } = useDialogState({
    initialState: false,
  });

  const isTargetPortfolio = portfolioMeta?.type !== NexoyaPortfolioType.Budget;
  const targetFunnelStep = portfolioMeta?.defaultOptimizationTarget;

  const hasPendingOptimizations = !!activeOptimizationData?.portfolioV2?.activeOptimization;
  const targetItem = summaryData?.newOptimizationSummary?.targetItem;
  const totalBudget = summaryData?.newOptimizationSummary?.totalBudget;

  const errorMessages = getErrorMessages(summaryData?.newOptimizationSummary, isTargetPortfolio, skipErrors);
  const handleSuccess = () => {
    createOptimization()
      .then(() => {
        toggleDialog();
        toggleSuccessDialog();
        track(EVENT.LAUNCH_OPTIMIZATION, {
          portfolioId,
          start: dayjs().startOf('d').format(GLOBAL_DATE_FORMAT),
          end: dayjs(optimizationEndDate).format(GLOBAL_DATE_FORMAT),
          totalBudget,
        });
      })
      .catch((reason) => toast.error(reason.message));
  };
  const diffDays = dayjs(optimizationEndDate).endOf('day').diff(dayjs().startOf('day'), 'd') + 1;

  return (
    <>
      <LaunchOptimizationSuccessDialog
        plannedBudget={totalBudget}
        timeframe={{ start: dayjs().startOf('d')?.toDate(), end: optimizationEndDate }}
        isOpen={isOpenSuccessDialog}
        onClose={closeDialog}
        isTargetPortfolio={isTargetPortfolio}
      />
      <ButtonAsync
        loading={loading}
        disabled={loading || hasPendingOptimizations}
        style={{
          padding: '8px 24px',
          marginLeft: 0,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          height: '100%',
        }}
        color="primary"
        variant="contained"
        onClick={() => {
          toggleDialog();
          track(EVENT.LAUNCH_OPTIMIZATION_DIALOG);
        }}
      >
        Launch optimization
      </ButtonAsync>
      <DialogStyled
        isOpen={isOpen}
        position="center"
        onClose={toggleDialog}
        paperProps={{
          style: {
            maxWidth: '662px',
            borderRadius: '12px',
          },
        }}
        backdropProps={{
          variant: 'dark',
        }}
        hideCloseButton={true}
      >
        <DialogHeaderStyled>
          <DialogTitle>
            <Calendar style={{ filter: 'grayscale(1)' }} />
            Launch an optimization
          </DialogTitle>
          <ButtonIcon onClick={toggleDialog}>
            <CancelIcon />
          </ButtonIcon>
        </DialogHeaderStyled>
        <DialogContentStyled>
          <DialogSubtitleStyled>
            The optimization will be launched today and we’ll notify you once it’s ready. It might take up to ~4hours to
            receive the results.
          </DialogSubtitleStyled>
          <DateSelectorWrapper>
            <DatepickerLabelStyled>Optimization end date</DatepickerLabelStyled>
            <SingleDateSelector
              disableAfterDate={dayjs(portfolioMeta?.end).toDate()}
              disableBeforeDate={dayjs().endOf('d').toDate()}
              onDateChange={({ selectedDate }) => setOptimizationEndDate(selectedDate)}
              selectedDate={optimizationEndDate}
              renderInputValue={(date) => {
                return (
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span>{dayjs(date).format('DD MMMM YYYY')}</span>
                    <span style={{ color: '#2A2A3273' }}>
                      Duration: {diffDays} day{diffDays > 1 ? 's' : ''}
                    </span>
                  </div>
                );
              }}
            />
          </DateSelectorWrapper>
          <StatusContainer>
            {summaryLoading ? (
              <BrickLoader style={{ height: 72, width: '100%', borderRadius: 5 }} />
            ) : errorMessages?.length ? (
              (errorMessages || []).map((error) => (
                <ErrorStatusWrapper skipped={skipErrors[error.id]} key={error.message}>
                  <SvgWarning style={{ color: '#AE1717' }} />
                  {error.message}
                  {error.isSkippable ? (
                    <Tooltip
                      placement="right"
                      variant="dark"
                      content="Skip the validation for this error"
                      popperProps={{
                        style: { zIndex: 3100 },
                      }}
                    >
                      <Checkbox
                        checked={skipErrors[error.id]}
                        onClick={() => setSkipErrors({ ...skipErrors, [error.id]: !skipErrors[error.id] })}
                      />
                    </Tooltip>
                  ) : null}
                </ErrorStatusWrapper>
              ))
            ) : (
              <StatusWrapperStyled>
                <StatusesContainer>
                  We'll draft an optimization proposal for this period{' '}
                  <span style={{ color: '#0EC76A' }}>
                    {format(dayjs().startOf('d'), 'D MMM')} -{' '}
                    {format(dayjs(optimizationEndDate).endOf('d'), 'DD MMM YYYY')}
                  </span>
                </StatusesContainer>
                {isTargetPortfolio ? (
                  <>
                    <StatusesContainer>
                      Maximum budget allowed:{' '}
                      <span style={{ color: '#0EC76A' }}>
                        <FormattedCurrency amount={targetItem?.maxBudget} />
                      </span>
                    </StatusesContainer>
                    <StatusesContainer>
                      Target per {capitalize(targetFunnelStep?.title)}:{' '}
                      <span style={{ color: '#0EC76A' }}>
                        <FormattedCurrency amount={targetItem?.value} />
                      </span>
                    </StatusesContainer>
                  </>
                ) : (
                  <StatusesContainer>
                    Approx. budget for the selected period:{' '}
                    <span style={{ color: '#0EC76A' }}>
                      <FormattedCurrency amount={totalBudget} />
                    </span>
                  </StatusesContainer>
                )}
              </StatusWrapperStyled>
            )}
          </StatusContainer>
        </DialogContentStyled>
        <DialogActionsStyled>
          <Button style={{ width: '100%' }} color="tertiary" variant="contained" onClick={toggleDialog}>
            Cancel
          </Button>
          <ButtonAsync
            style={{ width: '100%' }}
            color="primary"
            variant="contained"
            disabled={
              loadingOptimization ||
              summaryLoading ||
              !!summaryError ||
              !!errorMessages?.filter((e) => !e.isSkipped).length
            }
            loading={loadingOptimization || summaryLoading}
            onClick={handleSuccess}
          >
            Launch optimization
          </ButtonAsync>
        </DialogActionsStyled>
      </DialogStyled>
    </>
  );
};
