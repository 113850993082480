import {
  NexoyaBiddingStrategyType,
  NexoyaFunnelStepV2,
  NexoyaOptimizationPerformance,
  NexoyaOptimizedContentStatusReason,
  NexoyaOptimizedContentStatusType,
  NexoyaOptimizedTotal,
  NexoyaPortfolioType,
} from '../../../../types';

import FormattedCurrency from '../../../../components/FormattedCurrency';
import NumberValue from '../../../../components/NumberValue';

import { buildContentPath } from '../../../paths';
import { ExcludedContent } from './OptimizationProposalTable';
import { getAllContentsColumns, getChannelColumns, getImpactGroupColumns, getLabelColumns } from './columns';
import { IRowBiddingStrategy, RowRaw } from './optimizationDetailsTableTypes';
import { Row } from 'react-table';

const BUDGET_BACKGROUND = 'rgb(244, 253, 248)';
const FUNNEL_STEP_BACKGROUND = 'rgba(5, 168, 250, 0.10)';
const INACTIVE_FUNNEL_STYLE = {
  opacity: '0.5',
  fontSize: '12px',
};
const DEFAULT_STYLE = {
  justifyContent: 'center',
};

export const renderBiddingStrategyValueCell = (biddingStrategyRow: IRowBiddingStrategy) => {
  const biddingStrategyTypeCurrency = [
    NexoyaBiddingStrategyType.MaximizeConversions,
    NexoyaBiddingStrategyType.TargetCpa,
  ].includes(biddingStrategyRow?.type);

  if (biddingStrategyTypeCurrency) {
    return <FormattedCurrency amount={biddingStrategyRow?.value} />;
  } else {
    return (
      <NumberValue
        style={{ justifyContent: 'flex-end' }}
        value={biddingStrategyRow?.value}
        showChangePrefix
        textWithColor
        variant="default"
        datatype={{
          suffix: true,
          symbol: '%',
        }}
      />
    );
  }
};

export const isNullOrUndefined = (value: any) => value === null || value === undefined;

export const translateBiddingStrategyType = (biddingStrategyType: NexoyaBiddingStrategyType) => {
  switch (biddingStrategyType) {
    case NexoyaBiddingStrategyType.MaximizeConversions:
      return 'Target CPA';
    case NexoyaBiddingStrategyType.MaximizeConversionValue:
      return 'Target ROAS';
    case NexoyaBiddingStrategyType.TargetCpa:
      return 'Target CPA';
    case NexoyaBiddingStrategyType.TargetRoas:
      return 'Target ROAS';
    case NexoyaBiddingStrategyType.Other:
      return 'Other';
    default:
      return '';
  }
};

export const getColumnsBasedOnTableViewSwitch = ({
  funnelSteps,
  optimizedTotal,
  tableViewSwitch,
  tableMetricsSwitch,
  hasTargets,
  hasLifetimeBudget,
  hasLabels,
  portfolioType,
}: {
  funnelSteps: NexoyaFunnelStepV2[];
  optimizedTotal: NexoyaOptimizedTotal;
  tableViewSwitch: string;
  tableMetricsSwitch: string;
  hasTargets: boolean;
  hasLifetimeBudget: boolean;
  hasLabels: boolean;
  portfolioType: NexoyaPortfolioType;
}) => {
  switch (tableViewSwitch) {
    case 'impact-groups':
      return getImpactGroupColumns(funnelSteps, optimizedTotal, tableMetricsSwitch, tableViewSwitch, portfolioType);
    case 'channels':
      return getChannelColumns(funnelSteps, optimizedTotal, tableMetricsSwitch, tableViewSwitch, portfolioType);
    case 'labels':
      return getLabelColumns(funnelSteps, optimizedTotal, tableMetricsSwitch, tableViewSwitch, portfolioType);
    case 'focus':
      return getAllContentsColumns(
        funnelSteps,
        optimizedTotal,
        tableMetricsSwitch,
        tableViewSwitch,
        hasTargets,
        hasLifetimeBudget,
        hasLabels,
      );
    case 'all-content':
      return getAllContentsColumns(
        funnelSteps,
        optimizedTotal,
        tableMetricsSwitch,
        tableViewSwitch,
        hasTargets,
        hasLifetimeBudget,
        hasLabels,
      );
    default:
      return getAllContentsColumns(
        funnelSteps,
        optimizedTotal,
        tableMetricsSwitch,
        tableViewSwitch,
        hasTargets,
        hasLifetimeBudget,
        hasLabels,
      );
  }
};

export const getRowDataBasedOnTableViewSwitch = ({
  optimizationPerformance,
  tableView,
  skippedRows,
  rows,
}: {
  optimizationPerformance: NexoyaOptimizationPerformance;
  tableView: string;
  skippedRows: RowRaw[];
  rows: RowRaw[];
}): { groupedRowsRaw: any; groupedSkippedContentRowsRaw: any } => {
  switch (tableView) {
    case 'impact-groups':
      return {
        groupedRowsRaw: mergeRowsAndPerformanceData({ optimizationPerformance, rows, groupBy: 'impactGroups' }),
        groupedSkippedContentRowsRaw: [],
      };
    case 'channels':
      return {
        groupedRowsRaw: mergeRowsAndPerformanceData({ optimizationPerformance, rows, groupBy: 'channels' }),
        groupedSkippedContentRowsRaw: [],
      };
    case 'labels':
      return {
        groupedRowsRaw: mergeRowsAndPerformanceData({ optimizationPerformance, rows, groupBy: 'labels' }),
        groupedSkippedContentRowsRaw: [],
      };
    case 'focus':
      return {
        groupedRowsRaw: rows.filter(filterRowsByStatus),
        groupedSkippedContentRowsRaw: skippedRows.filter(filterRowsByStatus),
      };
    case 'all-content':
      return {
        groupedRowsRaw: rows,
        groupedSkippedContentRowsRaw: skippedRows,
      };
    default:
      return {
        groupedRowsRaw: rows,
        groupedSkippedContentRowsRaw: skippedRows,
      };
  }
};

function filterRowsByStatus(row: RowRaw) {
  if (!row.status) {
    return false; // Exclude rows without a status
  }

  if (row.status.type === NexoyaOptimizedContentStatusType.Skipped) {
    // For 'Skipped' status types, check if the reason is one of the specified reasons
    const validReasons = [
      NexoyaOptimizedContentStatusReason.NoBudget,
      NexoyaOptimizedContentStatusReason.SpendBelowThreshold,
      NexoyaOptimizedContentStatusReason.IsEnding,
    ];

    return validReasons.includes(row.status.reason);
  }
  return true;
}

export const getCustomCellStyles = (column, row, tableViewSwitch) => {
  const columnId = column.id?.toLowerCase();
  const impactGroupFunnelSteps = getImpactGroupFunnelSteps(row);
  const isFunnelStepColumn = isFunnelStepInColumn(columnId, impactGroupFunnelSteps);

  if (isBudgetColumn(columnId)) {
    return { background: BUDGET_BACKGROUND };
  }

  if (tableViewSwitch !== 'channels') {
    if (isFunnelStepColumn) {
      return { background: FUNNEL_STEP_BACKGROUND };
    }
    if (isInactiveFunnelColumn(columnId, impactGroupFunnelSteps)) {
      return INACTIVE_FUNNEL_STYLE;
    }
  }

  return DEFAULT_STYLE;
};

const getImpactGroupFunnelSteps = (row: Row) => {
  const impactGroupsTableMetricViewSwitch = 'impact-groups';
  const funnelSteps =
    row?.values?.impactGroup?.props['funnelSteps'] ||
    row?.values?.['impactGroup' + impactGroupsTableMetricViewSwitch]?.props['funnelSteps'];
  return funnelSteps ? funnelSteps.split(',') : [];
};

const isFunnelStepInColumn = (columnId: string, impactGroupFunnelSteps: string[]) =>
  impactGroupFunnelSteps.some((value) => columnId.includes(value));

const isBudgetColumn = (columnId: string) => columnId?.includes('budget') && columnId !== 'budgetHeader';

const isInactiveFunnelColumn = (columnId: string, impactGroupFunnelSteps: string[]) =>
  columnId.includes('funnel') && !impactGroupFunnelSteps.some((step) => columnId.includes(step));

const mergeRowsAndPerformanceData = ({
  optimizationPerformance,
  rows,
  groupBy,
}: {
  optimizationPerformance: NexoyaOptimizationPerformance;
  rows: RowRaw[];
  groupBy: keyof NexoyaOptimizationPerformance;
}): RowRaw[] => {
  // @ts-ignore -> map is not available on the budget type since the types don't overlap
  return optimizationPerformance?.[groupBy]?.map(
    // optimizedPerformance: NexoyaOptimizedLabel | NexoyaOptimizedChannel | NexoyaOptimizedImpactGroup
    (optimizedPerformance: any) => ({
      isExcluded: false,
      providerId: optimizedPerformance?.channelId ?? rows[0]?.providerId,
      // Default values needed for the return type & not to render anything redundant
      contentId: rows[0]?.contentId,
      title: rows[0]?.title,
      titleLink: rows[0]?.titleLink,
      isPerforming: rows[0]?.isPerforming,
      status: rows[0]?.status,
      lifetimeBudgetSegments: null,
      initialBiddingStrategy: null,
      proposedBiddingStrategy: null,
      biddingStrategyChangePercent: null,
      label: optimizedPerformance?.label,
      // Spent means previous daily budget
      previousDailyBudget: optimizedPerformance?.budget.spent,
      proposedDailyBudget: optimizedPerformance?.budget.proposed,
      proposedDailyBudgetChange: optimizedPerformance?.budget.changePercent,
      impactGroup: optimizedPerformance?.impactGroup,
      target: optimizedPerformance?.target,
      funnelSteps: rows[0]?.funnelSteps?.map((optimizedFunnelStep, idx) => {
        return {
          funnelStepId: optimizedFunnelStep?.funnelStepId,
          title: optimizedFunnelStep?.title,
          type: optimizedFunnelStep?.type,
          ...optimizedPerformance?.funnelSteps?.[idx],
        };
      }),
    }),
  );
};

export const contentToDataRow = ({
  contentRow,
  funnelSteps,
  start,
  end,
}: {
  contentRow: ExcludedContent;
  funnelSteps: NexoyaFunnelStepV2[];
  start: string;
  end: string;
}): RowRaw => {
  const title = contentRow?.content?.title;
  return {
    isExcluded: contentRow.isExcluded,
    providerId: contentRow?.content?.provider?.provider_id,
    contentId: contentRow?.content?.collection_id,
    title,
    titleLink: contentRow?.content?.collection_id
      ? buildContentPath(contentRow?.content?.collection_id, {
          dateFrom: start.substring(0, 10),
          dateTo: end.substring(0, 10),
        })
      : undefined,
    status: contentRow.status,
    lifetimeBudgetSegments: contentRow?.budgetProposalData?.lifetimeBudget?.lifetimeBudgetSegments,
    initialBiddingStrategy: {
      value: contentRow?.budgetProposalData?.initialBiddingStrategy?.value,
      type: contentRow?.budgetProposalData?.initialBiddingStrategy?.type,
    },
    proposedBiddingStrategy: {
      value: contentRow?.budgetProposalData?.proposedBiddingStrategy?.value,
      type: contentRow?.budgetProposalData?.proposedBiddingStrategy?.type,
      applicationDelta: contentRow?.budgetProposalData?.proposedBiddingStrategy?.applicationDelta,
      currentTcpa: contentRow?.budgetProposalData?.proposedBiddingStrategy?.currentTcpa,
      currentTroas: contentRow?.budgetProposalData?.proposedBiddingStrategy?.currentTroas,
      dailyBudgetChange: contentRow?.budgetProposalData?.proposedBiddingStrategy?.dailyBudgetChange,
      realizedRoas: contentRow?.budgetProposalData?.proposedBiddingStrategy?.realizedRoas,
      troasDelta: contentRow?.budgetProposalData?.proposedBiddingStrategy?.troasDelta,
      realizedCpa: contentRow?.budgetProposalData?.proposedBiddingStrategy?.realizedCpa,
      tcpaDelta: contentRow?.budgetProposalData?.proposedBiddingStrategy?.tcpaDelta,
    },
    biddingStrategyChangePercent: contentRow?.budgetProposalData?.biddingStrategyChangePercent,
    previousDailyBudget: contentRow?.budget?.spent,
    proposedDailyBudget: contentRow?.budget?.proposed,
    proposedDailyBudgetChange: contentRow?.budget?.changePercent,
    impactGroup: contentRow.impactGroup,
    target: contentRow.target,
    label: contentRow.label,
    funnelSteps: funnelSteps.map((funnelStep, idx) => ({
      __typename: null,
      ...funnelStep,
      ...contentRow?.funnelSteps?.[idx],
    })),
  };
};
