import { gql } from '@apollo/client';

export const BUDGET_MANAGER_FRAGMENT = gql`
  fragment BudgetProposalFragment on BudgetProposal {
    optimizationId
    timestampCreated
    timestampApplied
    status
    budgetProposalData {
      initialBiddingStrategy {
        type
        value
      }
      proposedBiddingStrategy {
        type
        value
        applicationDelta
        currentTcpa
        currentTroas
        dailyBudgetChange
        realizedRoas
        troasDelta
        realizedCpa
        tcpaDelta
      }
      lifetimeBudget {
        providerBudgetMissingDays
        lifetimeBudgetSegments {
          endDate
          initialBudget
          proposedBudget
          spend
          spendUpdatedAt
          startDate
        }
      }
      biddingStrategyChangePercent
      portfolioContentId
      budgetType
      initialBudget
      proposedBudget
      applicationType
    }
  }
`;
